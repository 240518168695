var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-sheet',{staticClass:"pa-4 d-flex flex-direction",attrs:{"rounded":_vm.$vuetify.breakpoint.smOnly ? '0' : 'lg'}},[_c('div',[_c('div',{staticClass:"mb-1 text-body4 text-sm-body2 primary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.rewardStore.totalCommentEarn)+" comments ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-title4 text-sm-title1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("round")(_vm.rewardStore.totalCommentReward,2))+" $INK ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.rewardStore.totalCommentReward)+" $INK")])])],1),_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"width":"40","height":"40","color":"primary darken-3","rounded":"circle"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/reward--post.svg'),"max-height":"20","max-width":"20","contain":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-sheet',{staticClass:"pa-4 d-flex flex-direction",attrs:{"rounded":_vm.$vuetify.breakpoint.smOnly ? '0' : 'lg'}},[_c('div',[_c('div',{staticClass:"mb-1 text-body4 text-sm-body2 primary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.rewardStore.totalPostEarn)+" posts ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-title4 text-sm-title1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("round")(_vm.rewardStore.totalPostReward,2))+" $INK ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.rewardStore.totalPostReward)+" $INK")])])],1),_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"width":"40","height":"40","color":"primary darken-3","rounded":"circle"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/reward--commented.svg'),"max-height":"20","max-width":"20","contain":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-sheet',{staticClass:"pa-4 d-flex flex-direction",attrs:{"rounded":_vm.$vuetify.breakpoint.smOnly ? '0' : 'lg'}},[_c('div',[_c('div',{staticClass:"mb-1 text-body4 text-sm-body2 primary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.rewardStore.totalUpvoteEarn)+" upvotes ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-title4 text-sm-title1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("round")(_vm.rewardStore.totalUpvoteReward,2))+" $INK ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.rewardStore.totalUpvoteReward)+" $INK")])])],1),_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"width":"40","height":"40","color":"primary darken-3","rounded":"circle"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/reward--like.svg'),"max-height":"20","max-width":"20","contain":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-sheet',{staticClass:"pa-4 d-flex flex-direction",attrs:{"rounded":_vm.$vuetify.breakpoint.smOnly ? '0' : 'lg'}},[_c('div',[_c('div',{staticClass:"mb-1 text-body4 text-sm-body2 primary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.rewardStore.totalInviteEarn)+" invites ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-title4 text-sm-title1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("round")(_vm.rewardStore.totalInviteReward,2))+" $INK ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.rewardStore.totalInviteReward)+" $INK")])])],1),_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"width":"40","height":"40","color":"primary darken-3","rounded":"circle"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/reward--invite.svg'),"max-height":"20","max-width":"20","contain":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-sheet',{staticClass:"pa-4 d-flex flex-direction",attrs:{"rounded":_vm.$vuetify.breakpoint.smOnly ? '0' : 'lg'}},[_c('div',[_c('div',{staticClass:"mb-1 text-body4 text-sm-body2 primary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.rewardStore.totalAcceptInviteEarn)+" invitations accepted ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-title4 text-sm-title1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("round")(_vm.rewardStore.totalAcceptInviteReward,2))+" $INK ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.rewardStore.totalAcceptInviteReward)+" $INK")])])],1),_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"width":"40","height":"40","color":"primary darken-3","rounded":"circle"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/reward--invite.svg'),"max-height":"20","max-width":"20","contain":""}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"12"}},[_c('v-sheet',{staticClass:"pa-4 d-flex flex-direction",attrs:{"rounded":_vm.$vuetify.breakpoint.smOnly ? '0' : 'lg'}},[_c('div',[_c('div',{staticClass:"mb-1 text-body4 text-sm-body2 primary--text text--lighten-2"},[_vm._v(" "+_vm._s(_vm.rewardStore.totalOtherEarn)+" other(s) ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-title4 text-sm-title1"},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("round")(_vm.rewardStore.totalOtherReward,2))+" $INK ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.rewardStore.totalOtherReward)+" $INK")])])],1),_c('v-sheet',{staticClass:"d-flex justify-center align-center",attrs:{"width":"40","height":"40","color":"primary darken-3","rounded":"circle"}},[_c('v-img',{attrs:{"src":require('@/assets/icons/other.svg'),"max-height":"20","max-width":"20","contain":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }