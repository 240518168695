






































































































































































import { rewardStore } from '@/stores/rewardStore'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { RewardViewModel } from '../../viewmodel/reward-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject({}) vm!: RewardViewModel
  rewardStore = rewardStore
}
